import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import PDLBorder from "../../components/PDLBorder";
import Logo from "../../components/Logo";
import '../../assets/css/crafting.css';
import AuthHeader from "../../components/AuthHeader";
import craftingText from '../../assets/img/crafting-txt.png';
import craftShade from '../../assets/img/craft-shade.png';
import {getItemsRequest} from "../../redux/Craft/action";
import Loader from "../NotFound/Loader";
import {Link, useNavigate} from "react-router-dom";
import {setLoader} from "../../redux/Site/action";
import {showLoader} from "../../redux/helpers";
import ItemWrapper from "../../components/ItemWrapper";


const Craft = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [search, setSearch] = useState(null);
    const [category, setCategory] = useState('all');
    const items = useSelector((state) => state?.Craft?.items);
    const categoryList = useSelector((state) => state.Site.category);
    const loader = useSelector((state) => state.Site.loader);
    // console.log(items)

    const onSearchHandler = async (event) => {
        event.preventDefault();
        showLoader('flex');
        await dispatch(getItemsRequest(null, `?search=${search}`));
        showLoader('none');
    }
    const onCategoryHandler = async (val) => {
        showLoader('flex');
        await dispatch(getItemsRequest(null, `?category=${val}`));
        // await props.getItems(null,`?category=${val}`);
        showLoader('none');
    }

    const get = async () => {
        showLoader('flex');
        dispatch(setLoader(true));
        await dispatch(getItemsRequest());
        // props.setLoader(false);
        showLoader('none');
        dispatch(setLoader(false));
    }
    const pagination = async (url) => {
        showLoader('flex');
        // await props.getItems();
        await dispatch(getItemsRequest(url));
        showLoader('none');
    }


    useEffect(() => {
        get();
        return () => {
        }
    }, []);


    if (loader) return <Loader/>

    return (
        <>
            <div className="frame-wrapper">

                {/* Border*/}
                <PDLBorder/>
                {/*Logo*/}
                <Logo/>

                {/*Menus*/}
                <div className="row">
                    {/*<div className="col-md-7">*/}

                    {/*</div>*/}
                    <div className="col-md-12">
                        <div className="Menus text-right">
                            <AuthHeader/>
                        </div>
                    </div>
                </div>
                {/*Menus*/}

                <section className="crafting_sec">
                    <div className="container">
                        <div className="heading-wrapper">
                            <div className="title">
                                <figure><img src={craftingText} alt="" className="img-fluid"/></figure>
                            </div>
                            <h4>items that can be crafted</h4>
                        </div>

                        <div className="row justify-content-center ">
                            <form className={"col-lg-8"}
                                  onSubmit={onSearchHandler}
                            >
                                {/*<div className="form-group d-flex justify-content-center align-content-center ">*/}
                                {/*    <input type="text" required={true}*/}
                                {/*           onChange={(e) => setSearch(e.target.value)}*/}
                                {/*           className="form-control pdlCustomInput"*/}
                                {/*           value={search}*/}
                                {/*           placeholder="Search item here"/>*/}
                                {/*    {*/}
                                {/*        search &&*/}
                                {/*        <a*/}
                                {/*            // type={"button"}*/}
                                {/*            onClick={() => {*/}
                                {/*                setSearch('');*/}
                                {/*                get();*/}
                                {/*            }}*/}
                                {/*            // className="btn m-0 customButton"*/}
                                {/*            className="p-1"*/}
                                {/*        >*/}
                                {/*            <i className={"text-danger fa fa-times"}></i>*/}
                                {/*        </a>*/}
                                {/*    }*/}
                                {/*</div>*/}
                                <div className="form-group d-flex justify-content-center align-content-center ">
                                    <select style={{
                                        width: '30%'
                                    }}
                                            name="category_id"
                                            id=""
                                            className="form-control pdlCustomInput"
                                            onChange={(e) => {
                                                setCategory(e.target.value);
                                                onCategoryHandler(e.target.value);
                                            }}
                                    >
                                        <option style={{
                                            background: 'rgba(0, 0, 0, 0.3)',
                                            color: '#fff',
                                        }} value={"all"}>All
                                        </option>
                                        {
                                            categoryList.map((val, key) => {
                                                return <option
                                                    key={key}
                                                    style={{
                                                        background: 'rgba(0, 0, 0, 0.3)',
                                                        color: '#fff',
                                                    }}
                                                    value={val?.id}>{val?.name}</option>
                                            })
                                        }
                                    </select>
                                    <input type="text" required={true}
                                           onChange={(e) => setSearch(e.target.value)}
                                           className="form-control pdlCustomInput"
                                           value={search}
                                           placeholder="Search item here"/>
                                    {
                                        search &&
                                        <a
                                            // type={"button"}
                                            onClick={() => {
                                                setSearch('');
                                                get();
                                            }}
                                            // className="btn m-0 customButton"
                                            className="p-1"
                                        >
                                            <i className={"text-danger fa fa-times"}></i>
                                        </a>
                                    }
                                </div>
                            </form>
                        </div>

                        <div className="all-crafting-wrapper">
                            {
                                items?.data?.length > 0 ?
                                    <>
                                        <div className="items row">
                                            {
                                                items?.data?.map((val, key) => (
                                                    <div
                                                        key={key}
                                                        className="col-lg-3"

                                                    >
                                                        <div className="mychar-wrapper text-center">
                                                            <ItemWrapper
                                                                // key={key}
                                                                className="col-12 p-0"
                                                                item={{
                                                                    title: val?.title,
                                                                    health: val?.health,
                                                                    base: val?.base,
                                                                    damage: val?.damage,
                                                                    armor_pen: val?.armor_pen,
                                                                    armor_pen_resist: val?.armor_pen_resist,
                                                                }}>
                                                                <div className="black-img-wrapper text-right">

                                                                    <figure className={"mr-1"}>
                                                                        <img src={val?.image_url}
                                                                             className="animate__animated animate__fadeInDown img-fluid"
                                                                             alt=""/>
                                                                    </figure>
                                                                </div>
                                                            </ItemWrapper>

                                                            <div className="my-char-content">
                                                                <div className="player-name row">
                                                                    <p className="col-12 m-0 justify-content-center Pname-title"
                                                                       style={{fontSize: 15}}>
                                                                        {val?.title}
                                                                    </p>
                                                                </div>
                                                                <Link to={`/craft-item/${val?.id}`}
                                                                      className="btn customButton">
                                                                    Craft Now
                                                                    {/*({parseInt(val?.stock).toLocaleString()})*/}
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    // <div key={key} onClick={() => navigate(`/craft-item/${val?.id}`)}
                                                    //     className="craft col-md-3">
                                                    //     <ItemWrapper
                                                    //         // key={key}
                                                    //         className="col-12 p-0"
                                                    //         item={{
                                                    //             title: val?.title,
                                                    //             health: val?.health,
                                                    //             base: val?.base,
                                                    //             damage: val?.damage,
                                                    //             armor_pen: val?.armor_pen,
                                                    //             armor_pen_resist: val?.armor_pen_resist,
                                                    //         }}>
                                                    //         <div className="img-box">
                                                    //             <figure className='mb-0'>
                                                    //                 <img src={val?.image_url} alt="" className="img-fluid"/>
                                                    //             </figure>
                                                    //             {/*<div className="shade">*/}
                                                    //             {/*    <img src={craftShade}*/}
                                                    //             {/*         alt="" className="img-fluid"/>*/}
                                                    //             {/*</div>*/}
                                                    //         </div>
                                                    //         {/*<div className="name">*/}
                                                    //         {/*    <h4>{val?.title}</h4>*/}
                                                    //         {/*</div>*/}
                                                    //     </ItemWrapper>
                                                    //
                                                    // </div>
                                                ))
                                            }
                                        </div>

                                        <div className="row mt-5 justify-content-center">
                                            {
                                                (items?.data?.length > 0) && items?.links?.map((val, key) => {
                                                    return <button key={key} type={"button"}
                                                                   disabled={(val?.url == null) && false}
                                                                   onClick={() => (val?.url && pagination(val?.url))}
                                                                   className="btn customButton mx-2"
                                                                   dangerouslySetInnerHTML={{__html: val?.label}}/>
                                                })
                                            }
                                        </div>
                                    </>
                                    :
                                    <div className={"row mt-5 justify-content-center"}>
                                        <div
                                            className="profile-creation text-center animate__animated animate__fadeInDown">
                                            <h3>Items Not Found</h3>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default Craft;