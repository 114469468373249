export const site_url = 'https://pdl-backend.developer-ourbase-camp.com/api';
export const site_web_url = 'https://pdl-backend.developer-ourbase-camp.com/';

// export const site_url = 'https://admin.playdeadlive.com/api';
// export const site_web_url = 'https://admin.playdeadlive.com/';

// export const site_url = 'http://127.0.0.1:8000/api';
// export const site_web_url = 'http://127.0.0.1:8000/';
//
export const loginAPi = `${site_url}/auth/signin`;
export const forgetPasswordAPi = `${site_url}/auth/forget-password`;
export const resetPasswordAPi = `${site_url}/auth/reset-password`;
export const signUpAPi = `${site_url}/auth/signup`;
export const submitInquiryApi = `${site_url}/submit-inquiry`;

export const profileApi = `${site_url}/user/profile`;
export const editProfileApi = `${site_url}/user/edit-profile`;
export const notificationApi = `${site_url}/user/notification`;
export const readNotificationApi = `${site_url}/user/read-notifications`;
export const deleteNotificationApi = (id) => `${site_url}/user/delete-notification/${id}`;
export const deleteAllNotificationApi = `${site_url}/user/delete-all`;

export const pagesAPi = `${site_url}/site/pages`;
export const charactersAPi = `${site_url}/site/characters`;
export const userCharacterApi = (id) => `${site_url}/site/user-character/${id}`;
export const categoryAPi = `${site_url}/site/category`;
export const settingAPi = `${site_url}/site/setting`;

export const sliderAPi = `${site_url}/site/slider`;
export const burnArcadeAPi = `${site_url}/site/burn-arcade`;
export const herosVillansAPi = `${site_url}/site/heros-villans`;
export const galleryAPi = `${site_url}/site/gallery`;
export const archiveHistoriesApi = `${site_url}/site/archive-histories`;
export const whatHappenNowApi = `${site_url}/site/what-happen-now`;
export const boxListApi = `${site_url}/site/box-list`;
export const famesApi = `${site_url}/site/wall-of-fames`;
export const famesSessionApi = `${site_url}/site/fame-session`;
export const currencyApi = `${site_url}/site/currency`;

export const searchUserApi = (name) =>{ return `${site_url}/user/search?name=${name}` };

export const createCharacterAPi = `${site_url}/user-character/create`;
export const editCharacterAPi = (id) => { return `${site_url}/user-character/edit/${id}`;}
export const getMyCharactersAPi = `${site_url}/user-character`;
export const changeMiscNotesAPi = `${site_url}/user-character/misc-notes`;
export const applyBionicsAPi = `${site_url}/user-character/bionic/apply`;
export const getMyCharacterAPi = (id) =>{ return `${site_url}/user-character/${id}` };
export const getCharacterDetailApi = (id,item_for) =>{ return `${site_url}/user-character/detail/${id}/${item_for}`};
// export const getCharacterInventoryApi = (type,id='') =>{ return `${site_url}/user-character/inventory/1`};
export const getCharacterInventoryApi = (type) =>{ return `${site_url}/user-character/inventory/${type}`};

export const getItemsAPi = `${site_url}/shop`;
export const buyItemAPi = `${site_url}/shop/buy`;
export const getItemAPi = (id) =>{ return `${site_url}/shop/${id}` };

export const getCraftItemsAPi = `${site_url}/shop/crafting`;
export const makeCraftItemApi = `${site_url}/shop/make-craft-item`;
export const getCraftItemAPi = (id) => `${site_url}/shop/crafting/${id}`;

export const getAuctionItemsAPi = `${site_url}/auction`;
export const getAuctionItemsForAuctionAPi = `${site_url}/auction?type=auction_list`;
export const myAuctionItemsAPi = `${site_url}/auction/myAuction`;
export const myBidsAuctionAPi = `${site_url}/auction/my-bids`;
export const createAuctionItemAPi = `${site_url}/auction/add-in-auction`;
export const placeABidAPi = `${site_url}/auction/place-a-bid`;
export const viewAuctionItemAPi = (id) => `${site_url}/auction/view/${id}`;

export const getInventoryAPi = `${site_url}/inventory`;
export const getAuctionInventoryAPi = `${site_url}/auction/inventory`;
export const sendToMainInventoryAPi = `${site_url}/auction/send-inventory`;
export const getSingleAuctionInventoryAPi = (id) =>{ return `${site_url}/auction/inventory/${id}` };

export const getFishInventoryAPi = `${site_url}/fish-inventory`;
export const getFishMoveTOInventoryAPi = `${site_url}/fish-inventory/move-to-inventory`;
export const getSingleFishInventoryAPi = (id) =>{ return `${site_url}/fish-inventory/${id}` };

export const getPetsInventoryApi = `${site_url}/inventory/pets`;
export const getGallryInventoryApi = (id) => `${site_url}/inventory/gallry/${id}`;
export const addFavoritePetApi = `${site_url}/inventory/favourite-pet`;
export const updateInventoryApi = `${site_url}/inventory/update/item-for`;
export const equipApi = `${site_url}/inventory/update/equip`;
export const unEquipApi = `${site_url}/inventory/update/unequip`;
export const getSingleInventoryAPi = (id) =>{ return `${site_url}/inventory/${id}` };
export const assignInventoryItemApi = () =>{ return `${site_url}/inventory/assign/item` };

export const getBagsApi = (character_id) =>{ return `${site_url}/bags/get/${character_id}` };
export const addBagsApi = `${site_url}/bags/add`;
export const returnToInventoryApi = (id,bagNo) => `${site_url}/bags/return/${id}/${bagNo}`;
export const removeBagsApi = (id) =>{ return `${site_url}/bags/remove-item/${id}` };

export const getTradeApi = (id) =>{ return `${site_url}/trade/get/${id}` };
export const newTradeApi = `${site_url}/trade/new`;
export const getTradeHistoryApi = (id) => { return `${site_url}/trade/get/history/${id}`};
export const newRejectApi = (id) => { return `${site_url}/trade/reject/${id}`};
export const newAcceptApi = (id) => { return `${site_url}/trade/accept/${id}`};
export const tradeCancelApi = (id) => { return `${site_url}/trade/cancel/${id}`};
export const addItemInTradeApi = `${site_url}/trade/addTradeItem`;
export const markAsCompleteApi = `${site_url}/trade/markAsComplete`;
export const finishedApi = `${site_url}/trade/finishTrade`;
export const updateTradeItemQtyApi = `${site_url}/trade/update/item/quantity`;


export const doEnhanceApi = `${site_url}/enhance/make`;
export const sendGiftApi = `${site_url}/gift/send`;

export const getPowerItems = `${site_url}/powerItem/get`;
export const powerItemApply = `${site_url}/powerItem/apply`;
export const removePowerItem = (id) => { return `${site_url}/powerItem/remove/${id}`};


export const listGuildApi = `${site_url}/guild/list`;
export const playersGuildApi = `${site_url}/guild/players`;
export const guildSettingApi = `${site_url}/guild/setting`;
export const showGuildApi = (id) => { return `${site_url}/guild/show/${id}`};
export const getGuildPlayerApi = (id) => { return `${site_url}/guild/get/players/${id}`};
export const notificationGuildApi = `${site_url}/guild/notification`;
export const readAllNotificationGuildApi = `${site_url}/guild/read-all-notification`;
export const createGuildApi = `${site_url}/guild/store`;
export const addItemGuildApi = `${site_url}/guild/add-item`;
export const sendItemGuildApi = `${site_url}/guild/send-item`;
export const reverseItemGuildApi = `${site_url}/guild/reverse-item`;
export const addPlayerGuildApi = `${site_url}/guild/add-player`;
export const leavePlayerGuildApi = `${site_url}/guild/leave`;
export const joinRequestGuildApi = `${site_url}/guild/request/join`;
export const acceptRequestGuildApi = `${site_url}/guild/request/accept`;
export const cancelRequestGuildApi = `${site_url}/guild/request/cancel`;
